<template>
  <div>
    <div
      v-if="$route.query.type == 'buy'"
      class="pay-detail col-center"
    >
      <img
        :class="payStatus ? 'pay-success-img' : 'pay-wait-img'"
        :src="payStatus ? require('../assets/icon/pay-success.png') :  require('../assets/icon/wait.png')"
      />
      <van-count-down
        v-if="!payStatus"
        class="time-limit-tip"
        :auto-start="autoStart"
        ref="order_count_down"
        :time="lefttime"
        format="mm 分 ss 秒"
        @finish="timeFinish"
      />
      <p
        v-if="payStatus"
        class="pay-success-tip"
        style="margin-bottom: 11.5625rem;margin-left: 1rem;"
      >支付成功！</p>
      <p
        v-if="!payStatus"
        class="pay-tip"
      >支付中…</p>
      <button
        v-if="payStatus"
        @click="seeCollection()"
        class="completed-pay"
      >查看藏品</button>
      <button
        v-if="payStatus"
        @click="goHome()"
        class="completed-pay"
      >返回首页</button>
      <button
        v-if="!payStatus"
        @click="getBuyOrderDetailsInfo(true)"
        class="completed-pay"
      >已完成支付</button>
    </div>
    <div
      v-if="$route.query.type == 'donation'"
      class="pay-detail col-center"
    >
      <img
        :class="payStatus ? 'pay-success-img' : 'pay-wait-img'"
        :src="payStatus ? require('../assets/icon/pay-success.png') :  require('../assets/icon/wait.png')"
      />
      <p
        v-if="payStatus"
        class="pay-success-tip"
      >支付成功！</p>
      <p
        class="pay-success-donation-title"
        v-if="payStatus"
      >藏品转赠操作已提交成功</p>
      <p
        class="pay-success-donation-tip"
        v-if="payStatus"
      >转赠需进行上链操作，有短暂延迟，请耐心等待。转赠成功后藏品自动到账，请通知接受人注意查收</p>
      <p
        v-if="!payStatus"
        class="pay-tip"
      >支付中…</p>
      <van-button
        v-if="payStatus"
        @click="getOrderDetails('donation')"
        class="completed-pay"
      >查看订单</van-button>
      <van-button
        v-if="!payStatus"
        @click="getDonationOrderDetailsInfo(true)"
        class="completed-pay"
      >已完成支付</van-button>
    </div>
    <div
      v-if="$route.query.type == 'composite'"
      class="pay-detail col-center"
    >
      <img
        :class="payStatus ? 'pay-success-img' : 'pay-wait-img'"
        :src="payStatus ? require('../assets/icon/pay-success.png') :  require('../assets/icon/wait.png')"
      />
      <p
        v-if="payStatus"
        class="pay-success-tip"
      >合成成功！</p>
      <!-- <p
        class="pay-success-donation-title"
        v-if="payStatus"
      >藏品转赠操作已提交成功</p> -->
      <p
        class="pay-success-donation-tip"
        v-if="payStatus"
      >合成需进行上链操作，有短暂延迟，请耐心等待。合成后藏品自动到账，请稍后查收。</p>
      <button
        v-if="payStatus"
        @click="goHome()"
        class="completed-pay"
      >返回首页</button>
      <p
        v-if="!payStatus"
        class="pay-tip"
      >支付中…</p>
      <van-button
        v-if="payStatus"
        @click="getOrderDetails('composite')"
        class="completed-pay"
      >查看订单</van-button>
      <van-button
        v-if="!payStatus"
        @click="getCompositeOrderInfos(true)"
        class="completed-pay"
      >已完成支付</van-button>
    </div>
  </div>

</template>
<script>
import api from '../api/index-client'
import Vue from 'vue'
import { CountDown,Button } from 'vant';
Vue.use(Button);
Vue.use(CountDown);

export default {
  data () {
    return {
      payStatus: false,
      orderInfo: {},
      lefttime: 300000,
      getorderStatus: null,
      isCountDown: true,
      autoStart: true
    }
  },
  mounted () {
    this.payStatus = false
    if (this.$route.query.type == 'buy') {
      this.getBuyOrderDetailsInfo(false)
    } else if (this.$route.query.type == 'composite') {
      this.getCompositeOrderInfos(false)
    } else {
      this.getDonationOrderDetailsInfo(false)
    }

  },
  activated () {
    this.$store.commit('SHOW_APPLOADING')
    setTimeout(() => {
      if (this.$route.query.type == 'buy') {
        this.getBuyOrderDetailsInfo(false)
      } else if (this.$route.query.type == 'composite') {
        this.getCompositeOrderInfos()

      } else {
        this.getDonationOrderDetailsInfo(false)
      }
    },1000)
  },
  methods: {
    showtime () {
      var nowtime = new Date()  //获取当前时间
      var endtime = new Date(this.orderInfo.createTime.replace(/-/g,"/"));  //定义结束时间
      this.lefttime = (endtime.getTime() + 300000) - (nowtime.getTime()) //距离结束时间的毫秒数
      this.autoStart = true
    },
    // 获取合成
    getCompositeOrderInfos (e) {
      api
        .get(`mall/mergetoken/order/${this.$route.query.unique}`)
        .then(result => {
          if (result.data.success) {
            this.orderInfo = result.data.data
            if (result.data.data.mergeTokenOrder.status != 'PAYING' && result.data.data.mergeTokenOrder.status != 'CANCELED') {
              this.payStatus = true
            } else if (result.data.data.mergeTokenOrder.status == 'CANCELED' || e) {
              this.$router.replace('/orderDetail?orderId=' + this.$route.query.unique + '&type=composite')
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 查询支付状态 
    getDonationOrderDetailsInfo (e) {
      api
        .post('gift/detail/' + this.$route.query.unique)
        .then(result => {
          if (result.data.success) {
            this.orderInfo = result.data.data
            if (this.orderInfo.status == 6 || this.orderInfo.status == 7) {
              this.payStatus = true
            } else if (this.orderInfo.status == 8 || e) {
              this.$router.replace('/orderDetail?orderId=' + this.$route.query.unique + '&type=donation')
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 查看藏品
    getOrderDetails (type) {
      this.$router.replace('/orderDetail?orderId=' + this.$route.query.unique + '&type=' + type)
    },
    // 查询支付状态 
    getBuyOrderDetailsInfo (e) {
      api
        .get('order/detail/' + this.$route.query.unique)
        .then(result => {
          if (result.data.success) {
            this.orderInfo = result.data.data
            if (result.data.data.status == 0 && result.data.data.refundStatus == 0 && result.data.data.paid == 1) {
              this.payStatus = true
            } else if (this.orderInfo.status == 0 && this.orderInfo.refundStatus == 0 && this.orderInfo.paid == 0 && !e && this.isCountDown) {
              this.showtime()
              this.isCountDown = false
            } else if (this.orderInfo.status == 4 && this.orderInfo.refundStatus == 0 && this.orderInfo.paid == 0 || e) {
              this.$router.replace('/orderDetail?unique=' + this.$route.query.unique + '&type=buy')
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    timeFinish () {
      this.$store.commit('SHOW_APPLOADING')
      this.orderCancer()
    },
    orderCancer () {
      api
        .get('order/detail/' + this.$route.query.unique)
        .then(result => {
          if (result.data.success) {
            this.$router.replace('/orderDetail?unique=' + this.$route.query.unique + '&type=buy')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 查看藏品
    seeCollection () {
      this.$router.push('/myCollection')
    },
    // 返回首页
    goHome () {
      this.$router.push('/')
    }
  },
}
</script>
<style lang="less" scoped>
.pay-detail {
  .van-button {
    width: 90%;
    height: 2.875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: 700;
    color: #ffffff;
    line-height: 1.375rem;
    letter-spacing: 0.125rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
</style>